import {NavLink, useNavigate} from "react-router-dom";
import * as Auth from "../Utility/Auth"
import {useEffect, useState} from "react";

function Header() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setIsAuthenticated(Auth.IsAuth());
    }, [navigate]);

    return (<div style={{position:"sticky",top:"0",backgroundImage:"linear-gradient(90deg, #414850, #131720)"}}>
        <NavLink className="button button-clear" to="/">Home</NavLink>
        {isAuthenticated
            ?
            <NavLink className="button button-clear" to="/Add">Add</NavLink>
            :
            <NavLink className="button button-clear" to="/LogIn">LogIn</NavLink>}
    </div>);
}

export default Header