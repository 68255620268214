import axios from "axios";
import * as Auth from "./Auth";

const path =window.location.origin;


function FetchDataGet(url) {
    const defaultOption = {headers: {Authorization: `Bearer ${Auth.GetToken()}`}};
    return new Promise((resolve, reject) => {
        axios.get(path + url, Auth.IsAuth()?defaultOption:{})
            .then(result => {
                return resolve(result);
            })
            .catch(err => {
                return reject(err)
            })
    })

}

function FetchDataPost(url, data) {
    const defaultOption = {headers: {Authorization: `Bearer ${Auth.GetToken()}`}};
    return new Promise((resolve, reject) => {
        axios.post(path + url, data, Auth.IsAuth()?defaultOption:{})
            .then(result => {
                return resolve(result)
            })
            .catch(err => {
                return reject(err)
            })
    })
}

export {FetchDataGet, FetchDataPost,path}