import * as Auth from "../Utility/Auth";
import {Navigate, useNavigate} from 'react-router-dom';
import {useEffect, useState} from "react";

import {FetchDataPost} from "../Utility/FetchDatas";

function Add() {
    const [survey, setSurvey] = useState({Title: ""});
    const navigate = useNavigate();

    const [question, setQuestion] = useState([{
        Text: "",
        Image: "",
        MultiSelect: false,
        IsTrue: false,
        Answers: [
            {Text: "", IsTrue: false},
            {Text: "", IsTrue: false}
        ]
    }]);
    useEffect(() => {

    }, [question]);

    function addSurvey(event) {
        event.preventDefault();
        const data = {
            Title: survey.Title,
            Questions: question
        };
        FetchDataPost("/addsurvey", data)
            .then(t => {
                return navigate("/");
            });
    }

    function questionChange(index, value) {
        const updatedQuestions = [...question];
        updatedQuestions[index] = {...updatedQuestions[index], Text: value};// Оновити текст питання
        setQuestion(updatedQuestions);// Оновити стан питань
    }

    function questionChangeMultiSelect(index, value) {
        const updatedQuestions = [...question];
        updatedQuestions[index] = {...updatedQuestions[index], MultiSelect: Boolean(value)};// Оновити текст питання

        updatedQuestions[index].Answers.map((ans) => {
            ans.IsTrue = false;
        });

        setQuestion(updatedQuestions);// Оновити стан питань
    }

    function questionChangeImage(data, index) {
        const updatedQuestions = [...question];
        updatedQuestions[index] = {...updatedQuestions[index], Image: data};
        setQuestion(updatedQuestions);
    }

    function questionChangeIsTrue(index, value) {
        const updatedQuestions = [...question];
        //updatedQuestions.map(q => (q.IsTrue = false));
        updatedQuestions[index] = {...updatedQuestions[index], IsTrue: Boolean(value)};// Оновити текст питання

        updatedQuestions[index].Answers.map((ans) => {
            ans.IsTrue = false;
        });

        setQuestion(updatedQuestions);// Оновити стан питань
    }

    function addQuestion() {
        const tempQuestion = [...question];
        tempQuestion.push({
            Text: "",
            Image: "",
            MultiSelect: false,
            IsTrue: false,
            Answers: [
                {Text: "", IsTrue: false},
                {Text: "", IsTrue: false}
            ]
        });
        setQuestion(tempQuestion);
    }

    function answerChange(questionIndex, answerIndex, value) {
        const updatedQuestions = [...question];
        updatedQuestions[questionIndex].Answers[answerIndex].Text = value;// Оновити текст відповіді
        setQuestion(updatedQuestions);// Оновити стан питань
    }

    function answerChangeIsTrue(questionIndex, answerIndex, value) {
        const updatedQuestions = [...question];
        if (!updatedQuestions[questionIndex].MultiSelect) {
            updatedQuestions[questionIndex].Answers.map(a => (a.IsTrue = false));
        }
        updatedQuestions[questionIndex].Answers[answerIndex].IsTrue = Boolean(value);// Оновити текст відповіді
        setQuestion(updatedQuestions);// Оновити стан питань
    }

    function addAnswer(questionIndex) {
        const updatedQuestions = [...question];
        updatedQuestions[questionIndex].Answers.push({Text: "", IsTrue: false});// Додати нову порожню відповідь
        setQuestion(updatedQuestions);// Оновити стан питань
    }

    function deleteAnswer(questionIndex, answerIndex) {
        const updatedQuestions = [...question];
        const answersCount = updatedQuestions[questionIndex].Answers.length;
        if (answersCount === 2) return;// Перевірити, чи є лише 2 відповіді, і вийти, якщо так
        updatedQuestions[questionIndex].Answers.splice(answerIndex, 1); // Видалити відповідь за індексом
        setQuestion(updatedQuestions);// Оновити стан питань
    }

    return !Auth.IsAuth() ? <Navigate to="/"/> : (// Перенаправлення на головну сторінку, якщо користувач не автентифікований
        <div>
            <form onSubmit={addSurvey}>
                <label>Title:</label>
                <input
                    type="text"
                    placeholder="Title"
                    value={survey.Title}
                    onChange={(e) => setSurvey({Title: e.target.value})}
                />
                <input type="button" value="add question" onClick={() => addQuestion()}/>
                {question.map((q, qIndex) => (
                    <div key={qIndex}>
                        <UploadImg setDataImage={(data) => questionChangeImage(data, qIndex)}/>
                        <label>Question:</label>
                        <textarea
                            value={q.Text}
                            onChange={(e) => questionChange(qIndex, e.target.value)}
                        />
                        <div style={{display: "flex"}}>

                            <label htmlFor={qIndex + "mult"}>Multi Select
                                <input onChange={(e) => questionChangeMultiSelect(qIndex, e.target.checked)}
                                       id={qIndex + "mult"} type="checkbox" checked={q.MultiSelect}/></label>

                            <label htmlFor={qIndex + "tru"}>Is True
                                <input onChange={(e) => questionChangeIsTrue(qIndex, e.target.checked)}
                                       id={qIndex + "tru"} type="checkbox" checked={q.IsTrue}/></label>
                        </div>
                        <input
                            style={{display: "flex"}}
                            type="button"
                            value="ADD"
                            onClick={() => addAnswer(qIndex)}
                        />

                        <label>Answers:</label>
                        {q.Answers.map((a, aIndex) => (
                            <div key={aIndex} style={{display: "flex"}}>
                                {q.IsTrue &&
                                    <input
                                        checked={a.IsTrue} name={"ans" + qIndex}
                                        onChange={(e) => answerChangeIsTrue(qIndex, aIndex, e.target.checked)}
                                        type={q.MultiSelect ? "checkbox" : "radio"}/>
                                }
                                <input
                                    placeholder="answer"
                                    value={a.Text}
                                    onChange={(e) => answerChange(qIndex, aIndex, e.target.value)}
                                />
                                <input
                                    type="button"
                                    value="Delete"
                                    onClick={() => deleteAnswer(qIndex, aIndex)}
                                />
                            </div>
                        ))}
                        <hr style={{height: "1px", border: "none", color: "#333", backgroundColor: "#333"}}/>
                    </div>
                ))}
                <input type="submit" value="Submit"/>
            </form>
        </div>
    );
}

export default Add;


export function UploadImg({setDataImage}) {
    const [imeg, setImeg] = useState(null);
    const [file, setFile] = useState({});

    useEffect(() => {
        if (file && file.length > 0) {
            let reader = new FileReader();
            if(file[0].size>10000) return;
            reader.readAsDataURL(file[0]);
            reader.onload = () => {
                let data = reader.result.toString();
                setImeg(data);
                setDataImage(data);
            };
        } else {
            setFile(null);
            setImeg(null);
            setDataImage(null);
        }
    }, [file]);

    function removeImg(event) {
        event.preventDefault(); // Запобігає стандартній поведінці
        event.stopPropagation(); // Запобігає розповсюдженню події
        setImeg(null);
        setFile(null); // Очистити також вибраний файл
        setDataImage("");
    }

    // Function to focus the file input when it needs to be shown


    return (

        <div>
            {imeg === "" || !imeg ?
                <div className="button">
                    <input
                        hidden
                        onChange={(e) => setFile(e.target.files)}
                        type="file"
                        accept="image/*"
                        id="file-input"
                        className="file-input__input"
                    />
                    <label className="file-input__label" htmlFor="file-input">
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="upload"
                            className="svg-inline--fa fa-upload fa-w-16"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width="24"
                        >
                            <path
                                fill="currentColor"
                                d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                            ></path>
                        </svg>
                        <span>Add Image</span>
                    </label>
                </div>
                :
                <div style={{position: 'relative'}}>
                    <input onClick={removeImg} type="button" value="x"
                           style={{position: 'absolute', fontFamily: "bold", top: 0, left: 0}}/>
                    <img src={imeg.toString()} alt=""
                         style={{maxWidth: "124px"}}/>
                </div>

            }
        </div>
    );

}

