// Імпорт необхідних модулів та компонентів
import React, {useEffect, useRef, useState} from "react";
import ItemSurvey from '../Components/ItemSurvey.js'
import {useInfiniteQuery} from 'react-query';
import {FetchDataGet} from "../Utility/FetchDatas";

// Головний компонент
function Home() {
    // Стан для зберігання висоти вікна
    const [Heigth, setHeight] = useState(window.innerHeight);
    // Стан для зберігання позиції прокрутки
    const scrollDiv = useRef(null);

    // Стан для зберігання посилання на елемент, який спостерігає за перетином
    const [observerTarget] = useState(useRef(null));

    // Функція для отримання даних опитування
    const fetchSurveys = async ({pageParam = 0}) => {
        const result = await FetchDataGet(`/getsurveys?startIndex=${pageParam}&count=${2}`);
        return result.data;
    };

    // Використання useInfiniteQuery для отримання даних опитування
    const {data, isLoading, isError, error, fetchNextPage, hasNextPage} = useInfiniteQuery('surveys', fetchSurveys, {
        getNextPageParam: (lastPage, pages) => pages.length * 2,
    });

    // useEffect для створення та очищення спостерігача перетину
    useEffect(() => {
        const target = observerTarget.current;

        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting) {
                    if (entries[0].isIntersecting && hasNextPage) {
                        fetchNextPage();
                    }
                }
            },
            {}
        );

        if (target) {
            observer.observe(target);
        }

        return () => {
            if (target) {
                observer.unobserve(target);
            }
        };
    }, [fetchNextPage, hasNextPage]);

    // useEffect для додавання та видалення обробника події зміни розміру
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // useEffect для встановлення позиції прокрутки при монтуванні компонента
    useEffect(() => {
        const scrollPos = localStorage.getItem('scrollPos');
        if (scrollPos && scrollDiv.current) {
            scrollDiv.current.scrollTop = scrollPos;
        }
    }, []);

    // Обробник події зміни розміру
    const handleResize = () => {
        setHeight(window.innerHeight);
    };

    // Обробник події прокрутки
    const handleScroll = () => {
        localStorage.setItem('scrollPos', scrollDiv.current.scrollTop);
    };

    // Повернення JSX
    if (isLoading) return <p>Завантаження...</p>;
    if (isError) return <p>Помилка: {error.message}</p>;

    return (
        <div id="scrolldiv" style={{overflowY: "scroll", height: `${Heigth - 75}px`}} onScroll={handleScroll} ref={scrollDiv}>
            {data.pages &&
                data.pages.flatMap((pageData, i) =>
                    pageData.map((item, index) => (
                        <div key={i * 2 + index}>
                            <ItemSurvey sur={item}/>
                            <br/>
                        </div>
                    ))
                )
            }
            <div ref={observerTarget}><p>Завантаження...</p>.</div>
        </div>
    );
}

// Експорт головного компонента
export default Home