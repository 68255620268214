import {useState} from "react";
import * as Auth from "../Utility/Auth";
import {Link} from "react-router-dom";
import {FetchDataPost,path} from "../Utility/FetchDatas"

const imegPath = `${path}/image/`;
const ItemSurvey = ({sur, detail = false}) => {

    const [survey] = useState(sur);


    const allUsers = new Set();

    survey.Questions.forEach(question => {
        question.Answers.forEach(answer => {
            answer.Users.forEach(user => {
                allUsers.add(user.GUID);
            });
        });
    });

    const totalUniqueUsersCount = allUsers.size;


    // Перевірка, чи sur визначено перед використанням його для ініціалізації стану
    if (!sur) {
        return null; // або можна повернути пустий рендер, або щось інше, як вам зручно
    }

    return (
        <div className={"card"} key={survey.GUID}>
            <Link onClick={() => {
                localStorage.setItem("survey", JSON.stringify(survey))
            }} to={`/Survey/${survey.GUID}`}>
                <div className={"header"}>
                    <div className={"title"}>
                        {survey.Title}
                    </div>

                </div>
            </Link>
            {!detail ?
                <ItemQuestion Question={survey.Questions[0]}/>
                :
                survey.Questions.map(ques => (
                    <ItemQuestion Question={ques}/>
                ))
            }

            <div className={"footer"}>
                <img src="../questions.svg" style={{verticalAlign: "middle"}}
                     alt="peoples"/> {survey.Questions.length}
                <img src="../person.svg" style={{verticalAlign: "middle"}} alt="peoples"/>{totalUniqueUsersCount}
            </div>

        </div>
    );
}
export default ItemSurvey

function ItemQuestion({Question}) {
    const [question, setQuestion] = useState(Question);

    const isSelect =
        question.Answers.some(answer =>
            answer.Users.some(user => user.GUID === Auth.GetUser()?.GUID ?? "")
        );
    const totalUsersCount = question.Answers.reduce((acc, answer) => {
        return acc + answer.Users.length;
    }, 0);

    function selectAnswer(guid) {
        const tempAnswer = [];

        let check = document.getElementsByName(guid)

        check.forEach((e) => {
            if (e.checked)
                tempAnswer.push(e.value);
        });
        if (tempAnswer.length === 0) return;

        FetchDataPost("/selectanswers", tempAnswer)
            .then(result => {
                setQuestion(result.data)
            })
    }

    return (
        <div>
            <div className={"card-body"}>
                <div className={"question-text"}>
                    {question.Image !== "" ?
                        <div>
                            <img src={imegPath + question.Image} alt={""} style={{maxWidth: "256px"}}/>
                        </div>
                        : ""
                    }

                    {question.Text}
                </div>


                {question.Answers.map((answer) => (
                        !isSelect ?
                            <label className={"label-check"} key={answer.GUID}>
                                <input
                                    disabled={!Auth.IsAuth()}
                                    type={question.MultiSelect ? "checkbox" : "radio"}
                                    value={answer.GUID}
                                    name={question.GUID}
                                />
                                <span>{answer.Text}</span>
                            </label>
                            :
                            <div key={answer.GUID}
                                 style={{borderBottom: "0px solid white", borderRadius: "0px"}}>
                                <label className={"label-check-selectet"}>
                                    <input disabled={true}
                                           checked={answer.Users.some(user => user.GUID === Auth.GetUser().GUID)}
                                           type={question.MultiSelect ? "checkbox" : "radio"}
                                           value={answer.GUID}
                                           name={question.GUID}
                                           style={{
                                               border: question.IsTrue ?
                                                   answer.IsTrue ?
                                                       "2px solid green"
                                                       :
                                                       "2px solid red"
                                                   : ""
                                           }}
                                    />
                                    <span>{answer.Text}</span>
                                </label>
                                <div
                                    style={{
                                        textAlign: "center",
                                        fontSize: "10px",
                                        fontWeight: 600,
                                        color: "black",
                                        display: "block",
                                        borderRadius: "5px",
                                        height: "15px",
                                        width: `${answer.Users.length / totalUsersCount * 100}%`,
                                        backgroundColor: question.IsTrue
                                            ?
                                            answer.IsTrue
                                                ?
                                                "green" : "red"
                                            :
                                            "white",
                                    }}>
                                    {answer.Users.length > 0 ? (answer.Users.length / totalUsersCount * 100) + "%" : ""}
                                </div>
                            </div>
                    )
                )}
            </div>

            {Auth.IsAuth() &&
                !isSelect &&
                <button className={"action"} onClick={() => {
                    selectAnswer(question.GUID)
                }} type="button">add result</button>
            }
        </div>
    );
}