import Home from "./Pages/Home";
import Add from "./Pages/Add";
import "./App.css";
import "./milligram.css"
import {
    BrowserRouter,
    Route,
    Routes
} from "react-router-dom";
import Header from "./Components/Header";
import LogIn from "./Pages/LogIn";
import Survey from "./Pages/Survey";
import {QueryClient, QueryClientProvider} from "react-query";

const queryClient = new QueryClient();

function App() {
    return (
        <BrowserRouter>
            <Header/>
            <QueryClientProvider client={queryClient}>
                <div className="Content">
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/Add" element={<Add/>}/>
                        <Route path="/LogIn" element={<LogIn/>}/>
                        <Route path="/Survey/:id" element={<Survey/>}/>
                    </Routes></div>
            </QueryClientProvider>
        </BrowserRouter>

    );
}

export default App;
