import {FetchDataPost} from "./FetchDatas";

export function IsAuth() {
    return localStorage.getItem("token") != null;
}

export function GetToken(){
    return localStorage.getItem("token")??"";
}
export function GetUser(){
    return JSON.parse(localStorage.getItem("user"));
}
export function Registration(user) {
    return new Promise((resolve, reject) => {
        FetchDataPost( "/registr", user).then(then => {
            if (then.data.token) {
                localStorage.setItem("token", then.data.token);
                localStorage.setItem("user",JSON.stringify(then.data.user));
                return resolve("ok");
            } else if (then.data.err) {
                return reject(then.data.err);
            }
        }).catch(err => {
            return reject(err);
        })
    })

}

export function Login(user) {
    return new Promise((resolve, reject) => {
        FetchDataPost( "/login", user).then(then => {
            localStorage.setItem("token", then.data.token);
            localStorage.setItem("user",JSON.stringify(then.data.user));
            return resolve("ok");
        }).catch(err => {
            return reject(err);
        })
    })

}


