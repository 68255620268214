import {useState} from "react";
import {Login, Registration} from "../Utility/Auth";
import {useNavigate} from "react-router-dom";

function LogIn() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [logreg, setLogreg] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();

    function handlelogin() {
        Login({Email: email, Pass: pass}).then(then => {
            return navigate("/");
        }).catch(err => setError(err));
    }

    function handleregistr() {
        Registration({Name: name, Email: email, Pass: pass}).then(then => {
            return navigate("/");

        }).catch(err => setError(err));
    }

    return (
        <>
            <div className="">LogIn</div>
            <div>{error}</div>
            <input checked={logreg} onChange={(e) =>
                setLogreg(!logreg)} type="checkbox" id="chk" aria-hidden="true"/>
            {logreg ?
                <div className="Login">
                    <label htmlFor="chk" aria-hidden="true">Login</label>
                    <input placeholder="email" value={email} type="text" onChange={(e) =>
                        setEmail(e.target.value)}/>
                    <input placeholder="pass" value={pass} type="text" onChange={(e) =>
                        setPass(e.target.value)}/>
                    <input type="button" value="Login" onClick={handlelogin}/>
                </div>
                :
                <div className="Registr">
                    <label htmlFor="chk" aria-hidden="true">Sign up</label>
                    <input placeholder="name" value={name} type="text" onChange={(e) =>
                        setName(e.target.value)}/>
                    <input placeholder="email" value={email} type="text" onChange={(e) =>
                        setEmail(e.target.value)}/>
                    <input placeholder="pass" value={pass} type="text" onChange={(e) =>
                        setPass(e.target.value)}/>
                    <input type="button" value="Registr" onClick={handleregistr}/>
                </div>
            }
        </>

    );
}

export default LogIn