import {useParams} from "react-router-dom";
import {useState, useEffect} from "react";
import {GetUser} from "../Utility/Auth";
import axios from "axios";
import * as Auth from "../Utility/Auth";
import ItemSurvey from "../Components/ItemSurvey";

function Survey() {
    const {id} = useParams();
    const [survey, setSurvey] = useState(null);

    useEffect(() => {
        // Отримання опитування з localStorage
        const storedSurvey = JSON.parse(localStorage.getItem("survey"));

        // Перевірка, чи опитування має такий самий GUID як id
        if (storedSurvey && storedSurvey.GUID === id) {
            setSurvey(storedSurvey);
        } else {
            // Якщо опитування не знайдено, можна виконати додаткові дії, наприклад, отримати дані з сервера
            // Або вивести повідомлення про помилку
            console.log("Survey not found");
        }
    }, [id]);


    // Відображення компоненту опитування, якщо survey встановлено
    return survey ? (
        <ItemSurvey sur={survey} detail={true}/>
    ) : null;
}

export default Survey;
